$indie: 'Indie Flower', cursive;
$cs: 'Coming Soon', cursive;

// Refactored
$LouisGeorgeCaf: 'Louis George Caf', sans-serif;
$LouisGeorgeCafBold: 'Louis George Caf Bold', sans-serif;
$Roboto: 'Roboto', sans-serif;
$Raleway: 'Raleway', sans-serif;

$darken: #202020;
$darker: #191919;
$darkest: #111111;
$black: #000000;
// $colors: #9400ff #37ff8b;
$blue-main: #005cb3;
$snow: #ffffff;
$red: #af0303;
$titlePink: #fbb7a6;
$textGrey: #bdbdbd;
$starYellow: #dfb842;

@mixin heightTopMax {
	@media screen and (min-width: 769px) and (max-height: 860px) {
		@content;
	}
}

@mixin heightTopMin {
	@media screen and (min-width: 769px) and (max-height: 808px) {
		@content;
	}
}

@mixin heightTopMinMax {
	@media screen and (min-width: 769px) and (max-height: 766px) {
		@content;
	}
}

@mixin laptop {
	@media screen and (max-width: 1440px) {
		@content;
	}
}

@mixin tabletBig {
	@media screen and (max-width: 1280px) {
		@content;
	}
}

@mixin tablet {
	@media screen and (max-width: 1024px) {
		@content;
	}
}

@mixin tabletSmall {
	@media screen and (max-width: 850px) {
		@content;
	}
}

@mixin mobile {
	@media screen and (max-width: 720px) {
		@content;
	}
}

@mixin mobileSmall {
	@media screen and (max-width: 359px) {
		@content;
	}
}
