@import './preloader.scss';

@import '../assets/fonts/fonts';
@import '../styles/reset';
@import '../styles/variables';
@import '../styles/base';
@import '../styles/animation';

// same time as CSSTransition timeout={} in App.tsx
$nextPageTime: 1.3s;

@keyframes transitionReload {
	0% {
		width: 0;
		height: 15px;
		bottom: 0;
	}
	30% {
		width: 100%;
		height: 15px;
		bottom: 0;
	}
	65% {
		width: 100%;
		height: 100%;
		bottom: 0;
	}
	100% {
		bottom: 100%;
		width: 100%;
		height: 0;
	}
}

.page {
	position: absolute;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	background-color: $darken;

	@include tablet {
		/* Fallback for browsers that do not support Custom Properties */
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
	}

	&.slide-exit {
		transform: translateY(-100%);
		transition: 0s linear;
		transition-delay: calc(65 * $nextPageTime / 100);
	}

	&:after {
		content: '';
		display: block;
		background-color: $blue-main;
		width: 0;
		height: 15px;
		transform: translateX(-50%);
		position: absolute;
		bottom: 0;
		left: 50%;
		z-index: 1000;
	}

	&.slide-enter:after {
		animation: transitionReload $nextPageTime ease;
	}
}
