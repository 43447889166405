@import '../../styles/variables';

.header {
	position: relative;
	z-index: 98;
	height: 90px;
	font-size: 18px;
	letter-spacing: 4px;
	font-family: $LouisGeorgeCafBold;
	display: flex;
	align-items: center;

	@include tablet {
		height: 80px;
		font-size: 21px;

		.wrapper {
			display: flex;
			align-items: center;
		}

		.logo,
		.mobile-menu-btn {
			position: relative;
			z-index: 100;
			flex-shrink: 0;
		}

		.nav-link {
			&:after {
				transform: scale(0);
				height: 100%;
				width: 100%;
			}

			&.active:after {
				transform: scale(1);
			}
		}
	}
}

.header-nav {
	display: flex;
	align-items: center;

	&--desktop {
		@include tablet {
			display: none;
		}
	}

	&__list {
		min-width: 55.6%;
		display: flex;
		justify-content: space-between;
		margin-left: auto;
	}
}

.logo-link {
	font-size: 23px;
	padding: 12px 0;
	letter-spacing: 5px;
}

.nav-item {
	margin-right: 70px;

	@include tablet {
		text-align: center;
		margin-right: 0;
		margin-bottom: 35px;
	}

	&:last-child {
		margin-right: 0;
	}
}

.nav-link {
	position: relative;
	z-index: 1;
	white-space: nowrap;
	font-family: $LouisGeorgeCafBold;
	padding: 12px 14px 12px 19px;
	letter-spacing: 5px;

	&:after {
		content: '';
		position: absolute;
		display: block;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 2px;
		background-color: $blue-main;
		z-index: -1;
		transition: height 0.3s ease, transform 0.3s ease;
	}

	&.active,
	&:hover {
		&:after {
			height: 100%;
		}
	}
}

.mobile-menu-btn,
.mobile-menu,
.wrapper > .logo {
	display: none;
}

@include tablet {
	.mobile-menu-btn,
	.mobile-menu,
	.wrapper > .logo {
		display: block;
	}

	.mobile-menu-btn {
		width: 40px;
		height: 22px;
		position: relative;
		margin-left: auto;
		transform: rotate(0deg);
		transition: 0.5s ease-in-out;

		span {
			display: block;
			position: absolute;
			height: 2px;
			width: 50%;
			background: $blue-main;
			opacity: 1;
			transform: rotate(0deg);
			transition: 0.25s ease-in-out;
		}

		span:nth-child(even) {
			left: 50%;
			border-radius: 0 10px 10px 0;
		}

		span:nth-child(odd) {
			left: 0px;
			border-radius: 10px 0 0 10px;
		}

		span:nth-child(1),
		span:nth-child(2) {
			top: 0px;
		}

		span:nth-child(3),
		span:nth-child(4) {
			top: 10px;
		}

		span:nth-child(5),
		span:nth-child(6) {
			bottom: 0;
		}
	}

	.mobile-menu {
		height: 0;
		opacity: 0;
		visibility: hidden;
		overflow: hidden;
		z-index: 99;

		&:after,
		&:before {
			content: '';
			display: block;
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
			z-index: 1;
		}

		&__content {
			height: 100%;
			height: calc(var(--vh, 1vh) * 100);
			overflow-x: hidden;
			overflow-y: auto;
			padding-top: 25vh;
			padding-bottom: 30px;
		}

		.nav-item,
		.contact-menu {
			transform: scale(0);
			transition: 0.2s;
		}

		.contact-menu {
			justify-content: center;
			padding: 10px;
		}

		&.open {
			height: 100vh;
			opacity: 1;
			visibility: visible;
			transform-style: preserve-3d;
			transform: translateZ(1px);

			.nav-item,
			.contact-menu {
				transform: scale(1);
			}

			.nav-item:first-child {
				transition-delay: 0.2s;
			}

			.nav-item:nth-child(2) {
				transition-delay: 0.25s;
			}

			.nav-item:last-child {
				transition-delay: 0.3s;
			}

			.contact-menu {
				transition-delay: 0.35s;
			}

			&:after,
			&:before {
				height: 100vh;
				z-index: -1;
			}

			&:before {
				background-color: $blue-main;
				animation: menuAnim 0.4s ease;
			}

			&:after {
				background-color: $darken;
				animation: menuAnim 0.6s ease;
			}
		}
	}

	.header-overflow {
		.mobile-menu-btn {
			span:nth-child(1),
			span:nth-child(6) {
				transform: rotate(45deg);
			}

			span:nth-child(2),
			span:nth-child(5) {
				transform: rotate(-45deg);
			}

			span:nth-child(1) {
				left: 3px;
				top: 4px;
			}

			span:nth-child(2) {
				left: calc(50% - 3px);
				top: 4px;
			}

			span:nth-child(3) {
				left: -50%;
				opacity: 0;
			}

			span:nth-child(4) {
				left: 100%;
				opacity: 0;
			}

			span:nth-child(5) {
				left: 3px;
				bottom: 3px;
			}

			span:nth-child(6) {
				left: calc(50% - 3px);
				bottom: 3px;
			}
		}
	}
}
