@font-face {
	font-family: 'Coming Soon';
	src: url('ComingSoon-Regular.woff2') format('woff2'),
		url('ComingSoon-Regular.woff') format('woff');
}

@font-face {
	font-family: 'Indie Flower';
	src: url('IndieFlower.woff2') format('woff2'), url('IndieFlower.woff') format('woff');
}

// the right implementations for fonts
@font-face {
	font-family: 'Louis George Caf';
	src: url('LouisGeorgeCafe.woff2') format('woff2'),
		url('LouisGeorgeCafe.woff') format('woff');
}

@font-face {
	font-family: 'Louis George Caf Bold';
	src: url('LouisGeorgeCafe-Bold.woff2') format('woff2'),
		url('LouisGeorgeCafe-Bold.woff') format('woff');
}

// Raleway fonts
@font-face {
	font-family: 'Raleway';
	src: url('Raleway-Regular.woff2') format('woff2'),
		url('Raleway-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Raleway';
	src: url('Raleway-Italic.woff2') format('woff2'),
		url('Raleway-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Raleway';
	src: url('Raleway-Medium.woff2') format('woff2'),
		url('Raleway-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Raleway';
	src: url('Raleway-MediumItalic.woff2') format('woff2'),
		url('Raleway-MediumItalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Raleway';
	src: url('Raleway-SemiBold.woff2') format('woff2'),
		url('Raleway-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Raleway';
	src: url('Raleway-SemiBoldItalic.woff2') format('woff2'),
		url('Raleway-SemiBoldItalic.woff') format('woff');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

// Roboto fonts
@font-face {
	font-family: 'Roboto';
	src: url('Roboto-Regular.woff2') format('woff2'),
		url('Roboto-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('Roboto-Italic.woff2') format('woff2'),
		url('Roboto-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('Roboto-Medium.woff2') format('woff2'),
		url('Roboto-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('Roboto-MediumItalic.woff2') format('woff2'),
		url('Roboto-MediumItalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}
