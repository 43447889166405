html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
ul,
li,
article,
canvas,
header,
nav,
button,
a {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	text-decoration: none;
}

html {
	-webkit-text-size-adjust: none;
	-moz-text-size-adjust: none;
	text-size-adjust: none;
}

* {
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

ul {
	list-style: none;
}

iframe {
	border: 0;
}

button {
	background: none;
	cursor: pointer;
}

a {
	color: inherit;
	text-decoration: none;
	display: inline-block;
}
a:focus,
a:active,
a:hover,
:focus {
	outline: none;
}
