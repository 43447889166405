@keyframes loaderSpinner {
	0%,
	100%,
	5% {
		opacity: 1;
	}
	95% {
		opacity: 0.05;
	}
}

// loader spinner time
$spinTime: 0.9s;

.preloader {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10000;
	width: 100%;
	height: 100%;
	background-color: #202020;
	display: flex;
	justify-content: center;
	align-items: center;

	&__spinner {
		position: relative;
		width: 60px;
		height: 60px;
		flex-shrink: 0;

		&-span {
			animation: loaderSpinner $spinTime infinite ease-in-out both;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;

			&:before {
				content: ' ';
				display: block;
				margin: 0 auto;
				width: 2px;
				height: 16px;
				border-radius: 20px;
				background-color: #ffffff;
			}

			@for $i from 1 through 12 {
				&:nth-child(#{$i}) {
					transform: rotate(($i - 1) * 30deg);
					animation-delay: calc(-#{$spinTime} + ($i - 1) * ($spinTime / 12));
				}
			}
		}
	}
}
